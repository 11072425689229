<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">协助程序文件（二层文件）的更改</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        帮你解决编制文件的适宜性、充分性、和有效性问题
        <br />
        写出来的文件不是形式主义，而是真正能有效落地的工作指引
      </p>
      <br />

      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <p class="SmallP">
        编写的文件是网上抄来的
        <br />
        其他公司模版在我的公司里应用水土不服
        <br />
        写文件的人写得很专业，操作的人却看不懂
      </p>
      <br />
      <p class="PTitle LeftBor">我们公司的解决方案：</p>
      <p class="SmallP">
        先培训编写文件的有效方法，辅以课堂练习启发学员
        <br />
        共同检讨文件编写的成果
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>